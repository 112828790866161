<!--
 * @Author: your name
 * @Date: 2022-04-06 12:07:36
 * @LastEditTime: 2022-05-06 18:18:35
 * @LastEditors: baymax-arch 1625750783@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \MyBoke\src\pages\photo\index.vue
-->
<template>
  <div
    v-show="isLoad"
    v-infinite-scroll="loadFn"
    :infinite-scroll-distance="flexScreen != 'mob' ? 500 : 400"
    :infinite-scroll-disabled="loadingWithout || loadingBtn || !isLoad"
    :infinite-scroll-delay="200"
    :class="{
      'infinite-list': true,
      photoMain: flexScreen != 'mob',
      photoMob: flexScreen == 'mob',
      overflow: 'auto',
    }"
  >
    <div class="photoDiv" id="photoMain" :style="{ height: mainHeight + 'px' }">
      <div
        v-for="(item, index) in list"
        class="photoItem"
        :key="index"
        :style="{
          height: 'auto',
          padding: '10px 8px',
        }"
        :ref="
          (el) => {
            itemRef[index] = el;
          }
        "
      >
        <slot
          name="slotTest3"
          :item="item"
          :index="index"
          :width="cloumnWidth + surplusWidth"
        ></slot>
      </div>
    </div>
    <el-button
      style="position: relative; z-index: 99"
      class="loadingBtn"
      v-show="loadingBtn"
      :loading="loadingBtn"
    >
      正在拉取新图片
    </el-button>
    <div :class="list.length ? 'without' : 'error'" v-show="loadingWithout">
      {{ list.length ? "-- 只能获取这么多 --" : "图片获取失败，服务器超时~" }}
    </div>
  </div>
</template>

<script setup>
import {
  defineProps,
  ref,
  defineExpose,
  defineEmits,
  onMounted,
  nextTick,
  toRaw,
} from "vue";
import useWaterFull from "@/hooks/useWaterFall.js";

let itemRef = ref([]);
let imgRef = ref([]);
// 瀑布流
let waterFullObj = ref({
  parentId: "photoMain",
  itemRef: itemRef.value,
  imgRef: imgRef.value,
  width: 150,
  surplusWidth: 1,
  list: props.list,
});

let useWaterFullGet = useWaterFull(waterFullObj.value, () => {
  setTimeout(function () {
    resizeDocData();
    waterfall();
  }, 10);
});

let {
  mainHeight,
  pushDom,
  resizeDocData,
  waterfall,
  flexScreen,
  width: cloumnWidth,
  surplusWidth,
} = useWaterFullGet;

let props = defineProps({
  list: {
    type: Array,
  },
  loadingWithout: {
    type: Boolean,
  },
  loadingBtn: {
    type: Boolean,
  },
  isLoad: {
    type: Boolean,
    default: true,
  },
});

let emits = defineEmits("[load]");

let loadFn = () => {
  props.isLoad && emits("load", pushDom);
};

defineExpose({
  pushDom,
});
</script>

<style lang="scss" >
.loadingBtn {
  padding: 8px !important;
  background-color: var(--white_divBgc) !important;
  color: var(--white_divFc) !important ;
  border: 1px solid var(--white_divBgc) !important;
}
.without {
  padding: 0 0 20px 10px;
  color: var(--white_divFc);
  font-size: 14px;
}
.error {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
.infinite-list {
  min-height: 500px;
  padding: 0;
  margin: 0;
  list-style: none;
}
.infinite-list .infinite-list-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  background: var(--el-color-primary-light-9);
  margin: 10px;
  color: var(--el-color-primary);
}
.infinite-list .infinite-list-item + .list-item {
  margin-top: 10px;
}
@media screen and (max-width: 768px) {
  .preview-content {
    transform: scale(0.4) rotateZ(0deg) !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .preview-content {
    transform: scale(0.5) rotateZ(0deg) !important;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1920px) {
  .preview-content {
    transform: scale(0.8) rotateZ(0deg) !important;
  }
}
.photoMain .photoDiv {
  width: 95%;
  height: auto;
  text-align: center;
  position: relative;
  margin: 10px auto;

  .photoItem {
    // border: 1px solid var(--white_divBoc);
    width: 200px;
    display: inline-block;
    box-sizing: border-box !important;
    position: absolute;

    .imgModal {
      // border: 1px solid red;
      box-sizing: border-box !important;
      position: relative;
      cursor: pointer;

      .imgModalItem {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        z-index: 1;
        background-color: rgba(0, 0, 0, 0.1);
        transition: all 0.3s;
        border-radius: 8px;

        &:hover {
          background-color: rgba(0, 0, 0, 0);
          color: var(--white_divFc_light);
        }

        span {
          position: absolute;
          right: 10px;
          bottom: 10px;
          color: #eee;
          font-size: 1px;
          background-color: rgba(0, 0, 0, 0.3) !important;
          padding: 5px;
          border-radius: 3px;
        }
      }

      img {
        z-index: 1;
        vertical-align: middle;
        border-radius: 8px;

        &:hover {
          transform: scale(1.1);
        }
      }
    }

    .desription {
      width: 100%;
      box-sizing: border-box !important;
      height: auto;
      display: flex;
      justify-content: flex-start;
      // border: 1px solid var(--white_divBoc);

      span {
        margin: 3px 3px 3px 0;
        color: var(--white_divFc_light);
        font-size: 12px;
        cursor: pointer;
        background-color: var(--white_divBoc);
        display: inline-flex;
        padding: 4px 8px;
        border-radius: 3px;
        align-items: center;
      }
    }
    .imgTitle {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 14px;
      padding: 10px 0 2px 0;
      span {
        text-align: left;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-weight: 550;
      }
    }
    .imgTool {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 8px;
      .ArticleToolVue {
        .like,
        .ping {
          padding: 0px 0px;
          display: flex;
          margin-right: 10px;
          box-sizing: border-box !important;
          cursor: pointer;
          -webkit-tap-highlight-color: transparent !important;
          span {
            color: var(--white_divFc_light) !important;
          }
        }
      }
    }
  }
}

.photoMob .photoDiv {
  height: auto;
  text-align: center;
  position: relative;
  margin: 0 auto;

  .photoItem {
    width: 200px;
    display: inline-block;
    box-sizing: border-box !important;
    position: absolute;

    .imgModal {
      // border: 1px solid red;
      box-sizing: border-box !important;
      position: relative;

      .imgModalItem {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        z-index: 1;
        background-color: rgba(0, 0, 0, 0.1);
        transition: all 0.3s;
        border-radius: 8px;

        span {
          position: absolute;
          right: 10px;
          bottom: 5px;
          color: #eee;
          font-size: 12px;
          background-color: rgba(0, 0, 0, 0.3) !important;
          padding: 5px;

          border-radius: 3px;
        }
      }

      img {
        z-index: 1;
        vertical-align: middle;
        border-radius: 8px;
      }
    }

    .desription {
      width: 100%;
      box-sizing: border-box !important;
      height: auto;
      display: flex;
      justify-content: flex-start;
      white-space: nowrap;
      overflow-x: scroll;

      span {
        margin: 5px 5px 5px 0;
        color: var(--white_divFc_light);
        font-size: 12px;
        background-color: var(--white_divBoc);
        display: inline-flex;
        padding: 4px 8px;

        border-radius: 3px;
        align-items: center;
      }
    }

    .imgTitle {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 14px;
      padding: 10px 0 0 0;
      span {
        text-align: left;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-weight: 550;
      }
    }
    .imgTool {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 8px;
      .ArticleToolVue {
        .like,
        .ping {
          padding: 0px 0px;
          display: flex;
          margin-right: 10px;
          box-sizing: border-box !important;
          cursor: pointer;
          -webkit-tap-highlight-color: transparent !important;
          span {
            color: var(--white_divFc_light) !important;
          }
        }
      }
    }
  }
}
</style>