<!--
 * @Author: your name
 * @Date: 2022-04-06 12:07:36
 * @LastEditTime: 2022-05-15 18:46:01
 * @LastEditors: baymax-arch 1625750783@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \MyBoke\src\pages\photo\index.vue
-->
<template>
  <div style="margin-bottom: 10px">
    <WaterFall
      :list="list"
      :loadingBtn="loadingBtn"
      :loadingWithout="loadingWithout"
      :scrollFlag="scrollFlag"
      @load="load"
      ref="WaterFallRef"
    >
      <template #slotTest3="scope">
        <div
          class="imgModal"
          @click="
            $router.push({ name: 'phoDetail', params: { id: scope.item.id } })
          "
        >
          <img
            :src="scope.item.img[0].imgurl"
            v-seen="{
              type: 'lazy',
              height: getHeight(scope.width, scope.item.img[0], scope.index),
            }"
            :style="{
              width: '100%',
              height: getHeight(scope.width, scope.item.img[0], scope.index),
            }"
            alt=""
          />
          <div class="imgModalItem">
            <span> 图集 * {{ scope.item.img[0].children.length }} </span>
          </div>
        </div>
        <div class="imgTitle">
          <span>{{ scope.item.title }}</span>
        </div>
        <div class="desription">
          <span
            v-for="(item, index) in scope.item.tag_name.split(',')"
            :key="index"
            >#{{ item }}
          </span>
        </div>
        <div class="imgTool">
          <ArticleToolVue
            class="ArticleToolVue"
            :likeNum="getIsLiked(scope.item.id)?.num || scope.item.likeNum"
            :commentNum="scope.item.commentNum"
            @pingFn="pingFn(scope.item.id)"
            @likeFn="likeFn(scope.item)"
            :isLikedMe="
              !!getIsLiked(scope.item.id)
                ? getIsLiked(scope.item.id).isLiked
                : scope.item.isLiked
            "
          />
        </div>
      </template>
    </WaterFall>
  </div>
</template>

<script setup>
import WaterFall from "./components/waterFall.vue";
import useLoading from "@/hooks/useLoading.js";
import { debounce, sleep, throllte } from "@/utils/public.js";
import { getRandomImg, like, likeCancel, isLikedFn } from "@/api/api.js"; //连接接口文件
import ArticleToolVue from "@/components/articleTool/articleTool.vue";
import { onActivated, onDeactivated, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import useScroll from "@/pages/home/useScroll.js";
import { useGetters } from "../../store/utils/useGetters.js";
import { isLogin, updateLoginShow } from "../../utils/user";
import useScreen from "@/hooks/useScreen.js";
import { toRefs, onMounted, computed } from "vue";
import { useHead } from "@vueuse/head";

let loading = useLoading();
useScroll(loading);
let store = useStore();
let router = useRouter();
let loadingBtn = ref(false);
let loadingWithout = ref(false);
let scrollFlag = ref(true);
let list = ref([]);
let limit = ref(10);
let current = ref(0);
let WaterFallRef = ref(null);
let { flexScreen } = toRefs(useScreen());

let getHeight = (width, img, index) => {
  console.log(width - 16);
  // 获取图片宽高像素
  let { w, h } = img;
  //获取元素宽度
  let n = width;
  // 计算图片宽高比
  let scale = h / w;
  console.log(n * scale - 16 - 2 + "px");
  return n * scale - 16 - 2 + "px";
  // 设置假高度
  // document.getElementsByClassName("imgId")[index].style.height = n * scale - 16 - 2 + 'px'
};

// 追加图片
let pushImg = async (pushFn) => {
  try {
    loadingBtn.value = true;
    current.value++;
    let { data: imgList, pageSize } = await getRandomImg({
      limit: limit.value,
      current: current.value,
    });

    let len = list.value.length;
    // 修改数据
    for (let i = 0; i < imgList.length; i++) {
      const v = imgList[i];
      list.value.push(v);
    }
    WaterFallRef.value.pushDom(len, list.value.length);
    loadingBtn.value = false;
    scrollFlag.value = true;
    if (current.value == pageSize) {
      loadingWithout.value = true;
    }
  } catch (err) {
    loadingBtn.value = false;
    loadingWithout.value = true;
  }
};

// 触底函数
const load = (pushFn) => {
  throllte(
    () => {
      if (scrollFlag.value && !loadingWithout.value) {
        console.log("多次加载。。。。。。。。。。。。");
        pushImg(pushFn);
      }
    },
    300,
    true
  );
};

// 点击评论跳转到文章
let pingFn = (id) => {
  store.commit("postInfo/UP_isFromComment", true);
  router.push({
    path: `/phoDetail/${id}`,
  });
};

// 点赞
let likeFn = (item) => {
  // 判断是否登陆
  if (!isLogin()) {
    updateLoginShow(true);
    return;
  }

  like({ id: Number(item.id), type: 3 }).then((res) => {
    if (res.msg === "你已点过赞了！") {
      likeCancel({ id: Number(item.id), type: 3 }).then((canRes) => {
        item.isLiked = false;
        item.likeNum--;
        store.commit("imgInfo/UP_LIKELIST", {
          type: "reduce",
          value: {
            id: item.id,
            num: item.likeNum,
          },
        });
      });
      return;
    }
    item.isLiked = true;
    item.likeNum++;
    store.commit("imgInfo/UP_LIKELIST", {
      type: "add",
      value: {
        id: item.id,
        num: item.likeNum,
      },
    });
  });
};

let { myLikeImgList } = useGetters("imgInfo", ["myLikeImgList"]);

let getIsLiked = (id) => {
  let flag = myLikeImgList.value.find((item) => item.id == id);
  return flag;
};

// 监听登陆状态为true的时候获取所有已存在列表的点赞状态
watch(
  () => store.state.userInfo.isLogin,
  async (newValue) => {
    if (newValue) {
      list.value.forEach(async (item) => {
        let isHas = myLikeImgList.value.some((pItem) => pItem.id == item.id);
        if (!isHas) {
          let { data } = await isLikedFn({ id: Number(item.id), type: 3 });
          item.isLiked = !!data;
          // 获取点赞状态
        } else {
          let flag = myLikeImgList.value.filter(
            (pItem) => pItem.id == item.id
          )[0].isLiked;
          item.isLiked = flag;
        }
      });
    }
  },
  {
    deep: true,
  }
);

onMounted(() => {
  pushImg();
});

onActivated(() => {
  useHead({
    // Can be static or computed
    title: "Baymax-图库中心",
    meta: [
      {
        name: `description`,
        content:
          "Baymax_图库中心,一个查看日常图的地方，分享日常摄影图，二次元等",
      },
      {
        name: `keywords`,
        content: "个人博客，图库，摄影，二次元",
      },
    ],
  });
  scrollFlag.value = true;
  store.commit("headInfo/UP_ACTIVE_LINK", "图库");
});

onDeactivated(() => {
  scrollFlag.value = false;
});
</script>

<style lang="scss" scoped>
.photoMain {
  height: auto;
  width: 45%;
  min-width: 700px;
  background-color: var(--white_divBgc);
  margin: 0 auto;
  color: var(--white_divFc);
  box-sizing: border-box !important;

  .photoDiv {
    width: 95%;
    height: auto;
    text-align: center;
    position: relative;
    margin: 10px auto;

    .photoItem {
      // border: 1px solid var(--white_divBoc);
      width: 200px;
      display: inline-block;
      box-sizing: border-box !important;
      position: absolute;

      .imgModal {
        // border: 1px solid red;
        box-sizing: border-box !important;
        position: relative;
        cursor: pointer;

        .imgModalItem {
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          position: absolute;
          z-index: 1;
          background-color: rgba(0, 0, 0, 0.1);
          transition: all 0.3s;
          border-radius: 8px;

          &:hover {
            background-color: rgba(0, 0, 0, 0);
            color: var(--white_divFc_light);
          }

          span {
            position: absolute;
            right: 10px;
            bottom: 10px;
            color: #eee;
            font-size: 1px;
            background-color: rgba(0, 0, 0, 0.3) !important;
            padding: 5px;
            border-radius: 3px;
          }
        }

        img {
          z-index: 1;
          vertical-align: middle;
          border-radius: 8px;

          &:hover {
            transform: scale(1.1);
          }
        }
      }

      .desription {
        width: 100%;
        box-sizing: border-box !important;
        height: auto;
        display: flex;
        justify-content: flex-start;
        // border: 1px solid var(--white_divBoc);

        span {
          margin: 3px 3px 3px 0;
          color: var(--white_divFc_light);
          font-size: 12px;
          cursor: pointer;
          background-color: var(--white_divBoc);
          display: inline-flex;
          padding: 4px 8px;
          border-radius: 3px;
          align-items: center;
        }
      }
      .imgTitle {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 14px;
        padding: 10px 0 2px 0;
        span {
          text-align: left;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          font-weight: 550;
        }
      }
      .imgTool {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 8px;
        .ArticleToolVue {
          .like,
          .ping {
            padding: 0px 0px;
            display: flex;
            margin-right: 10px;
            box-sizing: border-box !important;
            cursor: pointer;
            -webkit-tap-highlight-color: transparent !important;
            span {
              color: var(--white_divFc_light) !important;
            }
          }
        }
      }
    }
  }
}

.photoMob {
  height: auto;
  width: 100%;
  background-color: var(--white_divBgc);
  margin: 0 auto;
  color: var(--white_divFc);
  box-sizing: border-box !important;
  padding: 10px 0;

  .photoDiv {
    height: auto;
    text-align: center;
    position: relative;
    margin: 0 auto;

    .photoItem {
      width: 200px;
      display: inline-block;
      box-sizing: border-box !important;
      position: absolute;

      .imgModal {
        // border: 1px solid red;
        box-sizing: border-box !important;
        position: relative;

        .imgModalItem {
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          position: absolute;
          z-index: 1;
          background-color: rgba(0, 0, 0, 0.1);
          transition: all 0.3s;
          border-radius: 8px;

          span {
            position: absolute;
            right: 10px;
            bottom: 5px;
            color: #eee;
            font-size: 12px;
            background-color: rgba(0, 0, 0, 0.3) !important;
            padding: 5px;

            border-radius: 3px;
          }
        }

        img {
          z-index: 1;
          vertical-align: middle;
          border-radius: 8px;
        }
      }

      .desription {
        width: 100%;
        box-sizing: border-box !important;
        height: auto;
        display: flex;
        justify-content: flex-start;
        white-space: nowrap;
        overflow-x: scroll;

        span {
          margin: 5px 5px 5px 0;
          color: var(--white_divFc_light);
          font-size: 12px;
          background-color: var(--white_divBoc);
          display: inline-flex;
          padding: 4px 8px;

          border-radius: 3px;
          align-items: center;
        }
      }

      .imgTitle {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 14px;
        padding: 10px 0 0 0;
        span {
          text-align: left;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          font-weight: 550;
        }
      }
      .imgTool {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 8px;
        .ArticleToolVue {
          .like,
          .ping {
            padding: 0px 0px;
            display: flex;
            margin-right: 10px;
            box-sizing: border-box !important;
            cursor: pointer;
            -webkit-tap-highlight-color: transparent !important;
            span {
              color: var(--white_divFc_light) !important;
            }
          }
        }
      }
    }
  }
}
</style>