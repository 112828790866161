/*
 * @Author: your name
 * @Date: 2022-04-08 14:35:16
 * @LastEditTime: 2022-05-04 15:24:59
 * @LastEditors: baymax-arch 1625750783@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \MyBoke\src\hooks\useWaterFall.js
 */
import { ElNotification } from 'element-plus'

import { imgArrLoad, imgLoad, sleep } from '@/utils/public.js'
import {
  nextTick,
  onMounted,
  reactive,
  watch,
  toRefs,
  ref,
  onActivated,
  onDeactivated,
} from 'vue'
import useScreen from '@/hooks/useScreen.js'

export default function (
  obj = {
    itemRef: null,
    imgRef: null,
    width: null,
    surplusWidth: 1,
    parentWidth: null,
    cloumn: null,
    heightArr: null,
    mainHeight: null,
    parentId: null,
    list: null,
  },
  fn
) {
  let { flexScreen } = toRefs(useScreen())

  let info = reactive({
    itemRef: obj.itemRef || [],
    imgRef: obj.imgRef || [],
    width: obj.width || 150,
    surplusWidth: obj.surplusWidth || 0,
    parentWidth: obj.parentWidth || 0,
    cloumn: obj.cloumn || 0,
    heightArr: obj.heightArr || [],
    mainHeight: obj.mainHeight || 0,
    parentId: obj.parentId || 'photoMain',
    list: obj.list,
  })

  let {
    itemRef,
    imgRef,
    width,
    surplusWidth,
    parentWidth,
    cloumn,
    heightArr,
    mainHeight,
    parentId,
    list,
  } = toRefs(info)

  let done = ref(false)

  // 计算所需属性
  /**
   * @param {*} elementId 元素id
   */
  let calculation = elementId => {
    // 获取父级元素宽度
    parentWidth.value = document.getElementById(elementId.value).clientWidth
    // 计算得分一行多少元素
    cloumn.value = Math.floor(parentWidth.value / width.value)
    // 计算剩余宽度平均分  = 总宽度 - 元素宽度 / 元素个数
    surplusWidth.value =
      (parentWidth.value - cloumn.value * width.value) / cloumn.value
  }

  // 处理第一行
  /**
   * @param { Array } domArr 瀑布流dom数组
   * @param { Array } hArr //列高度数组
   * @param { number } index //当前元素在domArr中的索引
   *
   */
  let firstFn = (domArr, hArr, index) => {
    domArr[index].style.top = 0
    // left设置为索引*元素宽度 + 剩余宽度*当前所在列的索引
    domArr[index].style.left = `${
      index * width.value + index * surplusWidth.value
    }px`
    // 宽度设置为原来的宽度+剩余宽度平均分
    domArr[index].style['width'] = width.value + surplusWidth.value + 'px'

    // 元素高度push进高度列数组
    hArr.push(domArr[index].clientHeight)

    // 获取最高列撑开父级高度
    mainHeight.value = Math.max(...hArr)
  }

  // 处理第一行之后的元素，index为当前元素的索引
  /**
   * @param { Array } domArr 瀑布流dom数组
   * @param { Array } hArr //列高度数组
   * @param { number } index //当前元素在domArr中的索引
   *
   */
  let addFn = (domArr, hArr, index) => {
    done.value = true
    // 最低一列的索引
    let indexMin = 0

    // 最低一列的高度
    let heightMin = hArr[0]

    // 比较获取最小的
    for (let j = 0; j <= hArr.length; j++) {
      if (hArr[j] <= heightMin) {
        heightMin = hArr[j]
        indexMin = j
      }
    }

    // top设置为当前最低列的总高度
    if (domArr[index]) {
      domArr[index].style.top = heightMin + 'px'
      // left设置为索引*元素宽度 + 剩余宽度*当前所在列的索引
      domArr[index].style.left = `${
        indexMin * width.value + (indexMin % cloumn.value) * surplusWidth.value
      }px`

      // 宽度设置为原来的宽度+剩余宽度平均分
      domArr[index].style['width'] = width.value + surplusWidth.value + 'px'

      // 最低列加上元素高度
      hArr[indexMin] += domArr[index].clientHeight
      // 获取最高列撑开父级高度
      mainHeight.value = Math.max(...hArr)
      done.value = false
    }
  }

  // 重置瀑布流必要信息
  let resizeDocData = (w = flexScreen.value == 'mob' ? 180 : 240) => {
    width.value = w
    parentWidth.value = 0
    surplusWidth.value = 0
    cloumn.value = 0
    heightArr.value = []
  }

  // 生成瀑布流
  let waterfall = () => {
    calculation(parentId)
    for (let index = 0; index < itemRef.value.length; index++) {
      //第一行
      if (index <= cloumn.value - 1) {
        nextTick(() => {
          firstFn(itemRef.value, heightArr.value, index)
        })
      }
      // 第一行后面的
      else {
        nextTick(() => {
          addFn(itemRef.value, heightArr.value, index)
        })
      }
    }
  }

  // 追加dom
  let pushDom = (lenOld, newLen) => {
    if (done.value) {
      return
    }
    nextTick(() => {
      let len = itemRef.value.length
      for (let i = 0; i < newLen; i++) {
        let index = lenOld + i
        addFn(itemRef.value, heightArr.value, index)
      }
      resizeDocData()
      waterfall()
    })
  }

  // 监听屏幕尺寸
  watch(
    () => flexScreen.value,
    n => {
      if (window.onresize) {
        resizeDocData()
        waterfall()
      }
    }
  )

  onMounted(() => {
    window.onresize = function () {
      console.log(12367)
      resizeDocData()
      waterfall()
    }
    // 图片加载完成在结束loading
    setTimeout(() => {
      resizeDocData()
      waterfall()
      fn && fn()
    }, 1500)
  })

  onActivated(() => {
    resizeDocData()
    waterfall()
    window.onresize = function () {
      resizeDocData()
      waterfall()
    }
  })

  onDeactivated(() => {
    console.log(123333333333333333333333333)
    window.onresize = null
  })

  return {
    flexScreen,
    calculation,
    firstFn,
    addFn,
    resizeDocData,
    waterfall,
    mainHeight,
    heightArr,
    pushDom,
    width,
    surplusWidth,
  }
}
